export const signIn = (credentials) => {
  return (dispatch, getState, { getFirebase }) => {
    dispatch({ type: "LOADING_SIGN_IN" })
    const firebase = getFirebase()
    firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then((res) => {
        console.log(res);
        dispatch({ type: "SIGN_IN" })
      })
      .catch((err) => {
        dispatch({ type: "AUTH_ERROR", payload: "Wrong User or passowrd" })
      })
  }
}

export const signOut = () => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase()

    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch({ type: "SIGNOUT_SUCCESS" })
      })
  }
}
