import firebase from "firebase/app"
import "firebase/firestore"
import "firebase/auth"
import "firebase/storage"

// Replace this with your own config details
const firebaseConfig = {
  apiKey: "AIzaSyABi2wguEJ1pe49ZFzJ9woMWyGuBJO-Y6E",
  authDomain: "grocapp-f4eb9.firebaseapp.com",
  databaseURL: "https://grocapp-f4eb9.firebaseio.com",
  projectId: "grocapp-f4eb9",
  storageBucket: "grocapp-f4eb9.appspot.com",
  messagingSenderId: "753665290338",
  appId: "1:753665290338:web:642d8afdd8e636661ee13b",
  measurementId: "G-61RVRN5DH8",
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig)
firebase.firestore().settings({ timestampsInSnapshots: true })
firebase.storage()

export default firebase
