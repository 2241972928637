const initState = {
  authError: null,
  loadingSignIn: false
}

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case "LOADING_SIGN_IN":
      console.log("create subcategory loading")
      return { ...state, loadingSignIn: true }
    case "SIGN_IN":
      console.log("create subcategory success")
      return { ...state, loadingSignIn: false }
    case "AUTH_ERROR":
      console.log("create category error")
      return { ...state, loadingSignIn: false, authError: action.payload }
    default:
      return state
  }
}

export default authReducer
