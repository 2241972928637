const initState = {
  loadingAddCategory: false,
  loadingAddSubCategory: false,
  loadingAddFilter: false
};

const categoryReducer = (state = initState, action) => {
  switch (action.type) {
    case 'LOADING_ADD_CATEGORY':
      console.log('create category loading');
      return { ...state, loadingAddCategory: true };
    case 'ADD_CATEGORY':
      console.log('create category success');
      return { ...state, loadingAddCategory: false };
    case 'LOADING_ADD_SUB_CATEGORY':
      console.log('create subcategory loading');
      return { ...state, loadingAddCategory: true };
    case 'ADD_SUB_CATEGORY':
      console.log('create subcategory success');
      return { ...state, loadingAddCategory: false };
    case 'LOADING_ADD_FILTER':
      console.log('create subcategory loading');
      return { ...state, loadingAddFilter: true };
    case 'ADD_FILTER':
      console.log('create subcategory success');
      return { ...state, loadingAddFilter: false };
    case 'ADD_CATEGORY_ERROR':
      console.log('create category error');
      return state;
    default:
      return state;
  }
};

export default categoryReducer;
