import React from "react"
import { Switch, Redirect } from "react-router-dom"

import { RouteWithLayout } from "./components"
import { Main as MainLayout, Minimal as MinimalLayout } from "./layouts"
import { connect } from "react-redux"
// import {
//   Dashboard as DashboardView,
//   ProductList as ProductListView,
//   UserList as UserListView,
//   SignIn as SignInView,
//   NotFound as NotFoundView,
//   Oders as OdersView,
//   Category as CategoryView,
//   AlterStock as AlterStockView,
//   Subscription as SubscriptionView,
//   Banner as BannerView,
// } from "./views"

const Dashboard = React.lazy(() => import("./views/Dashboard"))
const NotFound = React.lazy(() => import("./views/NotFound"))
const ProductList = React.lazy(() => import("./views/ProductList"))
const AlterStock = React.lazy(() => import("./views/AlterStock"))
const SignIn = React.lazy(() => import("./views/SignIn"))
const UserList = React.lazy(() => import("./views/UserList"))
const Oders = React.lazy(() => import("./views/Oders"))
const Category = React.lazy(() => import("./views/Category"))
const Subscription = React.lazy(() => import("./views/Subscription"))
const Banner = React.lazy(() => import("./views/Banner"))
const Configure = React.lazy(() => import("./views/Configure"))

const Routes = (props) => {
  return props.auth.uid ? (
    <Switch>
      <Redirect exact from="/" to="/dashboard" />
      <Redirect exact from="/admin-app" to="/dashboard" />
      <Redirect exact from="/sign-in" to="/dashboard" />
      <RouteWithLayout
        component={Dashboard}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      <RouteWithLayout
        component={Oders}
        exact
        layout={MainLayout}
        path="/Orders"
      />
      <RouteWithLayout
        component={Subscription}
        exact
        layout={MainLayout}
        path="/Subscription"
      />
      <RouteWithLayout
        component={AlterStock}
        exact
        layout={MainLayout}
        path="/alterstock"
      />
      <RouteWithLayout
        component={ProductList}
        exact
        layout={MainLayout}
        path="/products"
      />
      <RouteWithLayout
        component={Category}
        exact
        layout={MainLayout}
        path="/category"
      />
      <RouteWithLayout
        component={Banner}
        exact
        layout={MainLayout}
        path="/banner"
      />
      <RouteWithLayout
        component={UserList}
        exact
        layout={MainLayout}
        path="/users"
      />
      <RouteWithLayout
        component={Configure}
        layout={MainLayout}
        exact
        path="/Configure"
      />
       <RouteWithLayout
        component={NotFound}
        layout={MinimalLayout}
        exact
        path="/Not-Found"
      />
      <Redirect to="/Not-Found" />
    </Switch>
  ) : (
    <Switch>
      <RouteWithLayout
        component={SignIn}
        layout={MinimalLayout}
        path="/sign-in"
      />
      <Redirect to="/sign-in" />
    </Switch>
  )
}
const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
  }
}

export default connect(mapStateToProps)(Routes)
