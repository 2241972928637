import authReducer from "./authReducer"
import bannerReducer from "./bannerReducer"
import categoryReducer from "./categoryReducer"
import ordersReducer from "./ordersReducer"
import productReducer from "./productReducer"
import { combineReducers } from "redux"
import { firestoreReducer } from "redux-firestore"
import { firebaseReducer } from "react-redux-firebase"

const rootReducer = combineReducers({
  auth: authReducer,
  banner: bannerReducer,
  category: categoryReducer,
  oders: ordersReducer,
  product: productReducer,
  firestore: firestoreReducer,
  firebase: firebaseReducer,
})

export default rootReducer
