import React, { useState } from "react"
import { Link as RouterLink } from "react-router-dom"
import clsx from "clsx"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/styles"
import { AppBar, Toolbar, Badge, Hidden, IconButton } from "@material-ui/core"
import MenuIcon from "@material-ui/icons/Menu"
import NotificationsIcon from "@material-ui/icons/NotificationsOutlined"
import InputIcon from "@material-ui/icons/Input"
import { signOut } from "../../../../redux/actions/authActions"
import { connect } from "react-redux"

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
  },
  flexGrow: {
    flexGrow: 1,
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
  },
  image: {
    width: "85px",
    height: "50px",
  },
}))

const Topbar = (props) => {
  const { className, onSidebarOpen, signout, ...rest } = props

  const classes = useStyles()

  const [notifications] = useState([])

  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <RouterLink to="/admin-app/dashboard">
          <img
            alt="Logo"
            src="images/logos/logo.png"
            className={classes.image}
          />
        </RouterLink>
        <div className={classes.flexGrow} />
        <IconButton color="inherit">
          <Badge
            badgeContent={notifications.length}
            color="primary"
            variant="dot"
          >
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <IconButton
          className={classes.signOutButton}
          color="inherit"
          onClick={() => {
            signout()
          }}
        >
          <InputIcon />
        </IconButton>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  )
}

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return {
    signout: () => dispatch(signOut()),
  }
}

export default connect(null, mapDispatchToProps)(Topbar)
