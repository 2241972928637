import React from "react"
import clsx from "clsx"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/styles"
import { Drawer } from "@material-ui/core"
import DashboardIcon from "@material-ui/icons/Dashboard"
import PeopleIcon from "@material-ui/icons/People"
import SettingsIcon from "@material-ui/icons/Settings"
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket"
import ListAltIcon from "@material-ui/icons/ListAlt"
import EditIcon from "@material-ui/icons/Edit"
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart"
import ViewCarouselIcon from "@material-ui/icons/ViewCarousel"
import { connect } from "react-redux"

import { SidebarNav } from "./components"

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up("lg")]: {
      marginTop: 64,
      height: "calc(100% - 64px)",
    },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
}))

const Sidebar = (props) => {
  const { open, variant, onClose, className } = props

  const classes = useStyles()

  const pages = [
    {
      title: "Dashboard (under Dev)",
      href: "/dashboard",
      icon: <DashboardIcon />,
    },
    {
      title: "Orders (in progress)",
      href: "/Orders",
      icon: <AddShoppingCartIcon />,
    },
    // {
    //   title: "Subscription (under Dev)",
    //   href: "/Subscription",
    //   icon: <PeopleIcon />,
    // },
    {
      title: "Alter Stock",
      href: "/alterstock",
      icon: <EditIcon />,
    },
    {
      title: "Products",
      href: "/products",
      icon: <ShoppingBasketIcon />,
    },
    {
      title: "Category",
      href: "/Category",
      icon: <ListAltIcon />,
    },
    {
      title: "Banners",
      href: "/banner",
      icon: <ViewCarouselIcon />,
    },
    {
      title: "Configure",
      href: "/Configure",
      icon: <SettingsIcon />,
    },
    // {
    //   title: "Users (under Dev)",
    //   href: "/users",
    //   icon: <PeopleIcon />,
    // },
  ]

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div className={clsx(classes.root, className)}>
        <SidebarNav className={classes.nav} pages={pages} />
      </div>
    </Drawer>
  )
}

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
}
const mapStateToProps = (state) => {
  // console.log(state);
  return {
    auth: state.firebase.auth,
  }
}

export default connect(mapStateToProps)(Sidebar)
