import React, { Suspense } from "react"
import { Router } from "react-router-dom"
import { createBrowserHistory } from "history"
import { Chart } from "react-chartjs-2"
import { ThemeProvider } from "@material-ui/styles"
import validate from "validate.js"

import { chartjs } from "./helpers"
import theme from "./theme"
import "react-perfect-scrollbar/dist/css/styles.css"
import "./assets/scss/index.scss"
import validators from "./common/validators"
import Routes from "./Routes"
import Backdrop from "@material-ui/core/Backdrop"
import CircularProgress from "@material-ui/core/CircularProgress"
import { makeStyles } from "@material-ui/core/styles"

// redux firebase
import { firestoreConnect } from "react-redux-firebase"

const browserHistory = createBrowserHistory()

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw,
})

validate.validators = {
  ...validate.validators,
  ...validators,
}
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}))
const App = () => {
  const classes = useStyles()

  return (
    <ThemeProvider theme={theme}>
      <Suspense
        fallback={
          <Backdrop
            className={classes.backdrop}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        }
      >
        <Router history={browserHistory}>
          <Routes />
        </Router>
      </Suspense>
    </ThemeProvider>
  )
}
export default firestoreConnect([
  {
    collection: "products",
  },
  {
    collection: "categories",
  },
])(App)
