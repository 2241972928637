const initState = {
  loadingAddProduct: false,
  loadingUpdateProduct: false,
  error: "",
}

const productReaducer = (state = initState, action) => {
  switch (action.type) {
    case "LOADING_ADD_PRODUCT":
      console.log("create Product loading")
      return { ...state, loadingAddProduct: true, error: "" }
    case "ADD_PRODUCT":
      console.log("create Product success")
      return { ...state, loadingAddProduct: false, error: "" }
    case "ADD_PRODUCT_ERROR":
      console.log("create Product error")
      return {
        ...state,
        loadingAddProduct: false,
        error: "something went wrong",
      }
    case "LOAD_UPDATE_PRODUCTS":
      console.log("Update Product loading")
      return { ...state, loadingUpdateProduct: true, error: "" }
    case "UPDATE_PRODUCTS":
      console.log("Update Product success")
      return {
        ...state,
        loadingUpdateProduct: false,
        error: "",
      }
    case "ERROR_UPDATE_PRODUCTS":
      console.log("Update Product error")
      return {
        ...state,
        loadingUpdateProduct: false,
        error: "error while updating product",
      }
    default:
      return state
  }
}

export default productReaducer
