//auth
export const LOADING_SIGN_IN = "LOADING_SIGN_IN"
export const SIGN_IN = "SIGN_IN"
export const ADD_AUTH_ERROR = "ADD_AUTH_ERROR"

//banners
export const LOADING_ADD_BANNER = "LOADING_ADD_BANNER"
export const ADD_BANNER = "ADD_BANNER"
export const ADD_BANNER_ERROR = "ADD_BANNER_ERROR"
export const LOADING_DELETE_BANNER = "LOADING_DELETE_BANNER"
export const DELETE_BANNER = "DELETE_BANNER"
export const DELETE_BANNER_ERROR = "DELETE_BANNER_ERROR"

//category
export const LOADING_ADD_CATEGORY = "LOADING_ADD_CATEGORY"
export const ADD_CATEGORY = "ADD_CATEGORY"
export const LOADING_ADD_SUB_CATEGORY = "LOADING_ADD_SUB_CATEGORY"
export const ADD_SUB_CATEGORY = "ADD_SUB_CATEGORY"
export const LOADING_ADD_FILTER = "LOADING_ADD_FILTER"
export const ADD_FILTER = "ADD_FILTER"
export const ADD_CATEGORY_ERROR = "ADD_CATEGORY_ERROR"

//orders
export const CONFIRM_ORDER = "CONFIRM_ORDER"
export const ORDER_CONFIRMED = "ORDER_CONFIRMED"
export const ERROR_WHILE_CONFIRMING = "ERROR_WHILE_CONFIRMING"

export const OUT_FOR_DELIVERY = "OUT_FOR_DELIVERY"
export const UPDATED_OUT_FOR_DELIVERY = "UPDATED_OUT_FOR_DELIVERY"
export const ERROR_OUT_FOR_DELIVERY = "ERROR_OUT_FOR_DELIVERY"

export const DELIVER_ORDER = "DELIVER_ORDER"
export const ORDER_DELIVERED = "ORDER_DELIVERED"
export const ERROR_WHILE_DELIVER = "ERROR_WHILE_DELIVER"
//products
export const LOADING_ADD_PRODUCT = "LOADING_ADD_PRODUCT"
export const ADD_PRODUCT = "ADD_PRODUCT"
export const ADD_PRODUCT_ERROR = "ADD_PRODUCT_ERROR"
export const LOAD_UPDATE_PRODUCTS = "LOAD_UPDATE_PRODUCTS"
export const UPDATE_PRODUCTS = "UPDATE_PRODUCTS"
export const ERROR_UPDATE_PRODUCTS = "ERROR_UPDATE_PRODUCTS"


