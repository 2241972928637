import {
  LOADING_ADD_BANNER,
  ADD_BANNER,
  ADD_BANNER_ERROR,
  LOADING_DELETE_BANNER,
  DELETE_BANNER,
  DELETE_BANNER_ERROR,
} from "../dispatch"

const initState = {
  loadingAddBanner: false,
  loadingDeleteBanner: false,
  error: "",
}

const bannerReducer = (state = initState, action) => {
  switch (action.type) {
    case LOADING_ADD_BANNER:
      console.log("ADDING BANNER")
      return {
        ...state,
        loadingAddBanner: true,
        error: false,
        errorMessage: "",
      }
    case ADD_BANNER:
      console.log("BANNER ADDED")
      return {
        ...state,
        loadingAddBanner: false,
        error: false,
        errorMessage: "",
      }
    case ADD_BANNER_ERROR:
      console.log("ERROR WHILE ADDING BANNER")
      return {
        ...state,
        loadingAddBanner: false,
        error: false,
        errorMessage: "couldn't Add,something went wrong!",
      }
    case LOADING_DELETE_BANNER:
      console.log("DELETING BANNER")
      return { ...state, loadingDeleteBanner: true, error: "" }
    case DELETE_BANNER:
      console.log("BANNER DELETE")
      return { ...state, loadingDeleteBanner: false, error: "" }
    case DELETE_BANNER_ERROR:
      console.log("ERROR WHILE DELETING ERROR")
      return {
        ...state,
        loadingAddBanner: false,
        error: false,
        errorMessage: "couldn't delete, something went wrong!",
      }
    default:
      return state
  }
}

export default bannerReducer
